module.exports = { 
    convert_to_text_title_meta: 'Online-Videoübersetzer – Untertitel- und Videoübersetzung für mehrere Sprachen',
    convert_to_text_desc_meta: 'Unterstützt über 40 Sprachen, einschließlich Englisch-Japanisch, Russisch-Englisch und Portugiesisch-Englisch, um online zweisprachige Untertitel zu erstellen und zu exportieren. Unser KI-gestützter Transkriptions- und Übersetzungsdienst erfüllt die Anforderungen der mehrsprachigen Videoübersetzung vollständig. Starten Sie noch heute mit einer kostenlosen Testversion!',
    convert_to_text_title: 'Video übersetzen',
    convert_to_text_desc: 'Übersetzen Sie Ihr Video automatisch in über 40 Sprachen, wie z.B. Englisch-Portugiesisch, Englisch-Spanisch und Englisch-Italienisch oder umgekehrt.',
    start_your_trial: 'Starten Sie Ihre kostenlose Testversion!',
    hero_img: './03_English.webp',
    step_one: 'Konvertieren Sie Ihre Sprache in Text',
    step_one_img: './translate_step1_en.webp',
    step_one_desc: 'Konvertieren Sie Ihr Video in Text mit unserer KI-Sprach-zu-Text-Engine. Stellen Sie sicher, dass Sie beim Hochladen die Sprache auswählen, die dem Video entspricht.',
    step_two: 'Übersetzungssprache auswählen',
    step_two_img: './translate_step2_en.webp',
    step_two_desc: 'Das Bearbeiten Ihres Originaltranskripts vor der Übersetzung kann die Genauigkeit verbessern. Unser System unterstützt verschiedene Sprachpaare wie Englisch-Vietnamesisch, Deutsch-Englisch und Arabisch-Englisch. Klicken Sie oben rechts auf die Schaltfläche, um in eine andere Sprache zu übersetzen.',
    step_three: 'Bearbeiten, verfeinern und exportieren',
    step_three_img: './translate_step3_en.webp',
    step_three_desc: 'Nach der Videoübersetzung wird eine Vergleichsansicht angezeigt, um Anpassungen vorzunehmen. Nach Abschluss sind Sie bereit, Ihre Übersetzung zu exportieren!',
    feature_tutorial_title: 'Lösung von Übersetzungsproblemen bei Videos und Audiodateien',
    feature_tutorial_desc: '<p>Der Videoübersetzer und Audio-zu-Text-Konverter von Taption wurde entwickelt, um häufig auftretende Probleme bei herkömmlichen Untertitelübersetzungen zu lösen. Unsere KI-gestützte Technologie überwindet diese Hauptprobleme:</p><ul><li><strong>Timing-Synchronisation:</strong> Passt präzise an, um sicherzustellen, dass Untertitel perfekt mit Video- und Audio-Timings synchronisiert sind.</li><li><strong>Inhaltsfluss:</strong> Verbessert die Kohärenz der Untertitel für ein reibungsloses Seherlebnis.</li><li><strong>Genauigkeit:</strong> Verhindert Diskrepanzen, die durch wörtliche Übersetzungen entstehen können, und stellt sicher, dass die Nachricht in allen Sprachen korrekt übermittelt wird.</li></ul><p>Mit der Unterstützung von über 40 Sprachen, einschließlich Englisch-Mandarin, Englisch-Japanisch, Englisch-Koreanisch oder Englisch-Thai, ermöglicht Ihnen Taption, zweisprachige Untertitel einfach zu erstellen und zu exportieren. Unsere Online-Plattform macht es einfach, diese Funktion zu nutzen, ohne Software herunterladen zu müssen. Ob Sie ein YouTube-Content-Ersteller sind, ein Pädagoge, der Vorlesungen aus Google Drive transkribiert, oder internationale Zoom- oder Webex-Konferenzen organisiert – die Videoübersetzungssoftware von Taption integriert sich nahtlos. Sie unterstützt die direkte Integration mit Plattformen wie YouTube, Google Drive, Zoom und Webex, sodass Sie Ihre Videoinhalte in verschiedenen Sprachen und Formaten übersetzen und verwalten können.</p><p>Weitere Informationen zu zweisprachigen Untertiteln finden Sie in diesem Tutorial <a class=\'link\' href=\'https://www.taption.com/add-subtitles\'>über zweisprachige Untertitel</a>. Schauen Sie sich das folgende Tutorial zu unserem automatisierten Transkriptions- und Übersetzungsservice an:</p>',
    feature_tutorial_poster_url: './translate_tutorial_poster.webp',
    feature_tutorial_youtube: 'https://www.youtube.com/embed/GyPnMIYkvAc?autoplay=1',
    feature_extra_title: 'Häufig gestellte Fragen zur Videoübersetzung',
    feature_extra_desc: '<ul><li><strong>Welche Arten von Video- und Audioformaten können übersetzt werden?</strong><p>Taption unterstützt eine Vielzahl von Video- und Audioformaten, einschließlich MOV, AVI, WMV, FLV, MPEG, OGG, MP4, WAV, MP3 und M4A. Diese Vielseitigkeit ermöglicht es Benutzern, eine breite Palette gängiger Dateitypen zu verarbeiten, um unterschiedliche Transkriptions- und Übersetzungsanforderungen zu erfüllen.</p></li><li><strong>Wie lange dauert die Video- oder Audioübersetzung?</strong><p>Unser KI-gestützter Videoübersetzer und unsere Sprach-zu-Text-Software arbeiten schnell und schließen die Transkription und Übersetzung in der Regel innerhalb von 1 Minute ab. Die genaue Dauer kann je nach Länge und Komplexität der Video- oder Audiodatei variieren.</p></li><li><strong>Wie erfolgt die Preisgestaltung für Video- und Audioübersetzungen?</strong><p>Für die Video- und Audioübersetzungsdienste von Taption, einschließlich YouTube-zu-Text-Konvertierungen, verwenden wir eine einzigartige Abrechnungsmethode. Wir ziehen die Hälfte der Gesamtminutenlänge der Video- oder Audiodatei ab. Wenn ein Video beispielsweise 20 Minuten lang ist, werden 10 Minuten vom Benutzerkonto abgezogen. Diese Preisgestaltung soll den Benutzern kostengünstige Transkriptions- und Übersetzungsdienste bieten.</p></li></ul>',
    feature_title: 'Automatischer Übersetzungsspeicher: Nutzen Sie das, was Sie bereits übersetzt haben.',
    feature_desc: '<p>Taption bewahrt alle Ihre Quell- und Zieltexte von zuvor übersetzten Videos oder Audios an einem Ort auf. Wann immer es einen ähnlichen oder identischen Satz entdeckt, sehen Sie sofort die passende Übersetzung—so können Sie sie bei Bedarf wiederverwenden oder anpassen. Es ist ein einfacher Weg, um Zeit zu sparen, konsistent zu bleiben und mehr zu erledigen.</p> <ul> <li><strong>Automatisierter Speicher</strong>: Sammelt alle Ihre früheren Übersetzungen an einem zentralen Ort.</li> <li><strong>Schnelles Abgleichen</strong>: Ermittelt ähnliche oder identische Quelltexte und zeigt Ihnen sofort die korrekte Übersetzung.</li><li><strong>Bedeutungsbasierte Ähnlichkeiten</strong>: Nicht nur auf Zeichenebene, sondern erfasst auch die Bedeutung hinter dem Text für eine genauere Übersetzung.</li> <li><strong>Einfache Wiederverwendung</strong>: Ermöglicht es Ihnen, die ursprüngliche Übersetzung beizubehalten oder kleine Anpassungen vorzunehmen.</li> <li><strong>Konsistenz und Produktivität</strong>: Hilft Ihnen, denselben Stil und dieselbe Terminologie über Projekte hinweg beizubehalten und beschleunigt den gesamten Übersetzungsprozess.</li> </ul>',
    feature_poster_url:'./Translation_Memory_English.webp',
    feature_youtube: 'https://www.youtube.com/embed/3VJx1KM1txQ?autoplay=1',
}