module.exports = { 
    convert_to_text_title_meta: 'Tradutor de vídeos online - Tradução de legendas e vídeos em múltiplos idiomas',
    convert_to_text_desc_meta: 'Suporta mais de 40 idiomas, incluindo inglês para japonês, russo para inglês e português para inglês para criar e exportar legendas bilíngues online. O nosso serviço de transcrição e tradução alimentado por IA atende plenamente às necessidades de tradução multilíngue de vídeos. Comece uma avaliação gratuita hoje!',
    convert_to_text_title: 'Traduzir vídeo',
    convert_to_text_desc: 'Traduza automaticamente o seu vídeo para mais de 40 idiomas, como inglês para português, inglês para espanhol e inglês para italiano, ou vice-versa.',
    start_your_trial: 'Comece o seu teste grátis!',
    hero_img: './03_English.webp',
    step_one: 'Converta o seu discurso em texto',
    step_one_img: './translate_step1_en.webp',
    step_one_desc: 'Converta o seu vídeo em texto usando o nosso motor de reconhecimento de voz por IA. Certifique-se de escolher o idioma correspondente ao vídeo ao fazer o upload.',
    step_two: 'Selecione o idioma para tradução',
    step_two_img: './translate_step2_en.webp',
    step_two_desc: 'Editar a sua transcrição original antes de traduzir pode melhorar a precisão. O nosso sistema suporta vários pares de idiomas como inglês para vietnamita, alemão para inglês e árabe para inglês. Clique no botão no canto superior direito para traduzir para outro idioma.',
    step_three: 'Edite, refine e exporte',
    step_three_img: './translate_step3_en.webp',
    step_three_desc: 'Após a tradução do vídeo, estará visível uma vista de comparação lado a lado para ajudar a fazer ajustes. Uma vez completo, está pronto para exportar a sua tradução!',
    feature_tutorial_title: 'Resolvendo desafios de tradução de vídeos e áudios',
    feature_tutorial_desc: '<p>O tradutor de vídeos e o conversor de áudio para texto do Taption são projetados para resolver problemas comuns em processos tradicionais de tradução de legendas. A nossa tecnologia alimentada por IA supera estes desafios principais:</p><ul><li><strong>Sincronização de Tempo:</strong> Ajustes precisos para garantir que as legendas estejam perfeitamente sincronizadas com o tempo do vídeo e áudio.</li><li><strong>Fluxo de Conteúdo:</strong> Melhora a coerência das legendas para proporcionar uma experiência de visualização fluida.</li><li><strong>Precisão:</strong> Evita discrepâncias que traduções literais possam causar, garantindo uma entrega precisa da mensagem entre idiomas.</li></ul><p>Suportando mais de 40 idiomas, incluindo inglês para mandarim, inglês para japonês, inglês para coreano ou inglês para tailandês, o Taption permite criar e exportar legendas bilíngues com facilidade. A nossa plataforma online elimina a necessidade de download de software, facilitando o início do uso desta funcionalidade. Seja um criador de conteúdo do YouTube, um educador transcrevendo palestras guardadas no Google Drive ou organizando conferências internacionais no Zoom ou Webex, o software de tradução de vídeo do Taption integra-se perfeitamente. Suporta integração direta com plataformas como YouTube, Google Drive, Zoom e Webex, facilitando a tradução e gestão do seu conteúdo de vídeo em diversos idiomas e formatos.</p><p>Para legendas bilíngues, consulte este tutorial <a class=\'link\' href=\'https://www.taption.com/add-subtitles\'>sobre legendas bilíngues</a>. Confira o tutorial abaixo sobre o nosso serviço de transcrição e tradução automatizada:</p>',
    feature_tutorial_poster_url: './translate_tutorial_poster.webp',
    feature_tutorial_youtube: 'https://www.youtube.com/embed/GyPnMIYkvAc?autoplay=1',
    feature_extra_title: 'Perguntas frequentes sobre tradução de vídeo',
    feature_extra_desc: '<ul><li><strong>Que tipos de formatos de vídeo e áudio podem ser traduzidos?</strong><p>O Taption suporta vários formatos de vídeo e áudio, incluindo MOV, AVI, WMV, FLV, MPEG, OGG, MP4, WAV, MP3 e M4A. Essa versatilidade permite aos utilizadores lidar com uma ampla gama de tipos de ficheiros comuns para atender às diferentes necessidades de transcrição e tradução.</p></li><li><strong>Quanto tempo leva para traduzir um vídeo ou áudio?</strong><p>O nosso tradutor de vídeo alimentado por IA e software de conversão de fala para texto funcionam rapidamente, completando normalmente a transcrição e tradução em 1 minuto. O tempo específico pode variar com base na duração e complexidade do ficheiro de vídeo ou áudio.</p></li><li><strong>Qual é o padrão de preços para tradução de vídeo e áudio?</strong><p>Para os serviços de tradução de vídeo e áudio do Taption, incluindo conversões de YouTube para texto, usamos um método de faturação único. Deduzimos metade do número de minutos do comprimento total do ficheiro de vídeo ou áudio. Por exemplo, se um vídeo durar 20 minutos, 10 minutos serão deduzidos da conta do utilizador. Este método de preços é projetado para fornecer aos utilizadores serviços de transcrição e tradução rentáveis.</p></li></ul>',
    feature_title: 'Memória de tradução automatizada: Use o que você já traduziu',
    feature_desc: '<p>Taption mantém todas as suas frases de origem e destino de vídeos ou áudios previamente traduzidos em um só lugar. Sempre que detecta uma frase semelhante ou idêntica, você verá a tradução correspondente imediatamente—para que possa reutilizá-la ou ajustá-la conforme necessário. É uma maneira simples de economizar tempo, manter a consistência e aumentar a produtividade.</p> <ul> <li><strong>Memória Automatizada</strong>: Reúne todas as suas traduções anteriores em um local central.</li> <li><strong>Correspondência Rápida</strong>: Detecta texto de origem semelhante ou idêntico e mostra a tradução certa de imediato.</li><li><strong>Similaridades Baseadas no Significado</strong>: Não se limita à correspondência por nível de caracteres—também captura o significado por trás do texto para uma tradução mais precisa.</li> <li><strong>Reutilização Fácil</strong>: Permite que você escolha entre manter a tradução original ou fazer pequenos ajustes.</li> <li><strong>Consistência e Produtividade</strong>: Ajuda você a manter o mesmo estilo e terminologia em diferentes projetos, acelerando todo o processo de tradução.</li> </ul>',
    feature_poster_url:'./Translation_Memory_English.webp',
    feature_youtube: 'https://www.youtube.com/embed/3VJx1KM1txQ?autoplay=1',
}