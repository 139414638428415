module.exports = {
   convert_to_text_title_meta:"线上翻译软体：自动影片翻译",
   convert_to_text_desc_meta:"线上自动翻译将MP4影片、MP3录音或Youtube影片转换为文字，再翻译成超过40种语言，并支援将双语字幕嵌入影片后导出。免费体验，立即注册开始使用！",
   convert_to_text_title:"自动影片翻译",
   convert_to_text_desc:"线上自动翻译将MP4影片、MP3录音、Youtube影片转为文字后翻译成超过40多种语言，影片上字幕神器！",
   start_your_trial:"马上试！",
   hero_img:"./03_Chinese.webp",
  
   step_one:"将影音转为文字",
   step_one_img:"./select_source_zh.webp",
   step_one_desc:"翻译前先将影片转为文字，上传时选择的语言要和影片本身相同。这个过程涉及语音转文字技术,可以将各种格式如MP4、MOV等影片转换成文字。",
  
   step_two:"选择翻译语言",
   step_two_img:"./translate_step2_zh.webp",
   step_two_desc:"校正文字后可从编辑平台右上方进行翻译。在翻译前对原文进行校正可达到更好的翻译结果。支援40多种语言翻译,包括英文翻译、中翻英、日文翻译、韩文翻译、印尼翻译、越南翻译等。",
  
   step_three:"编辑后导出",
   step_three_img:"./translate_step3_zh.webp",
   step_three_desc:"翻译后会有对照平台进行调整后即可导出！支援多种格式如MP4影片档、SRT字幕档、TXT逐字稿等。",

   feature_title:'自动翻译记忆库：善用过去上传影片的翻译',
   feature_desc:'<p>Taption 会将你在过去影片或音档中翻译过的所有原文与翻译文集中管理。每当侦测到相似或相同的句子或片语时，你会立即看到对应的翻译，让你可以直接重用或稍作调整。这样不但能节省时间，也能确保整体风格一致，让你更快完成更多翻译工作。 </p> <ul> <li><strong>自动记忆</strong>：将你过去的所有翻译集中到同一个地方管理。 </li> <li><strong>快速比对</strong>：侦测到类似或相同的来源文字时，马上显示相对应的翻译。 </li><li><strong>意义导向对照</strong>：不只局限于文字结构的比较，也会根据原文的语意来寻找相似句。 </li> <li><strong>重复使用</strong>：让你决定是否采用原本的翻译，或是微调后再使用。 </li> <li><strong>维持一致与提升效率</strong>：协助在各专案中保持相同的用语与风格，加快整体翻译流程。 </li> </ul>',
   feature_youtube:'https://www.youtube.com/embed/FJu3miFaBSk?autoplay=1',
   feature_poster_url:'./Translation_Memory_Chinese.webp',

   feature_tutorial_title:'翻译影片教学',
   feature_tutorial_desc:'<p>Taption 的影片翻译软体专为解决传统字幕翻译过程中的常见问题而设计。我们的技术克服了以下三大挑战，保证翻译质量和观看体验：</p><ul><li><strong>时间同步：</strong>精确校正，确保翻译后的字幕与影片时间完美同步。 </li><li><strong>内容流畅：</strong>改善字幕连贯性，提供顺畅的观看体验。 </li><li><strong>意义准确：</strong>避免直译导致的意义偏差，传达正确信息。 </li></ul><p>支持超过40种语言，包括英文、日文、韩文、越南文等，Taption 让您能够轻松创建和导出双语字幕，全方位满足多语言影片翻译需求。透过我们的线上平台，无需下载任何软件，即可开始使用此功能。无论您是影片制作人、教育工作者还是国际会议的组织者，Taption 的影片翻译软体都是您最佳的选择，与其说是线上翻译软体，使用过的用户会称其为线上翻译神器！ </p>无论是将MP4或是MP3进行翻译，我们的编辑平台都让整个流程更顺畅和省时。如果想要双语字幕可参考这篇<a class="link" href="https://www.taption.com/add-subtitles/zh">双语字幕的教学</a>。快来看以下自动翻译的教学影片：',
   feature_tutorial_poster_url:'./translate_tutorial_poster.webp',
   feature_tutorial_youtube:'https://www.youtube.com/embed/3KbT6st8nYU?autoplay=1',
  
   feature_extra_title:"常见问题",
   feature_extra_desc:"<ul><li><strong>可以翻译哪些类型的影片格式？</strong><p>Taption提供便捷的自动转档功能支援多种影片和音频格式，包括mov、avi、wmv、flv 、mpeg、ogg、mp4、m4a、wav 以及mp3，满足不同需求。我们还直接支援Webex、Zoom会议、Google Drive 影音档案，以及YouTube 影片的下载和转档后的翻译功能。</p></ li><li><strong>影片翻译需要多长时间？</strong><p>在Taption上，影片翻译的时间通常很快，一般情况下翻译过程会在1分钟以内完成。具体时间可能会根据影片本身的长度和复杂度有所不同。</p></li><li><strong>影片翻译的收费标准是怎样的？</strong><p>对于Taption 的影片翻译和字幕生成，其独特的计费方式是按照影片的总长度的一半分钟数扣除。例如，如果一个YouTube 影片的长度是20 分钟，则从用户的账户中扣除10 分钟的分钟数。这种计费方式适用于各类影片翻译包括长用的：英翻中、日文翻译、韩文翻译、印尼翻译、越南语翻译等服务，旨在为用户提供成本效益高的线上翻译服务。</p></li> </ul>"
  
   //feature_extra_desc:"影片内容翻译功能带来的价值：<ul><li>增加影片受众，能让你的影片使不同语言的观众能够更方便欣赏、谈论和分享影片，对Youtuber来说，更是流量密码之一</li><li>优质的翻译影片非常适合作为学习语言的教材，上了双语字幕的影片更能吸引想用影片来学习语言的观众</li><li>将影片内容翻译成外语字幕，对于专业译者而言可以增加工作效率，对小团体或一人工作室的影片制作还能省去请翻译的成本</li><li>商业宣传影片之中，往往需要与外商合作，有一支翻译影片能够迅速让客户了解业务内容，就能事半功倍</li></ul>"
  }