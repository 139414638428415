module.exports = {
    convert_to_text_title_meta:"Online Video Translator - Subtitle and Video Translation for Multiple Languages",
    convert_to_text_desc_meta:"Support over 40 languages including English to Japanese, Russian to English, and Portuguese to English for creating and exporting bilingual subtitles online. Our AI-powered transcription and translation service fully meets the needs of multilingual video translation. Start with a free trial today!",
    convert_to_text_title:"Translate video",
    convert_to_text_desc:"Automatically translate your video into 40+ languages, such as English to Portuguese, English to Spanish, and English to Italian, or vice versa.",
    start_your_trial:"Start your free trial!",
    hero_img:"./03_English.webp",

    step_one:"Convert your speech to text",
    step_one_img:"./translate_step1_en.webp",
    step_one_desc:"Convert your video to text using our AI speech-to-text engine. Ensure you choose the language matching the video when uploading.",
    
    step_two:"Select translating language",
    step_two_img:"./translate_step2_en.webp",
    step_two_desc:"Editing your original transcript before translating can improve accuracy.Our system supports various language pairs like English to Vietnamese, German to English, and Arabic to English. Click the button on the top right to translate into another language.",

    step_three:"Edit, refine and export",
    step_three_img:"./translate_step3_en.webp",
    step_three_desc:"A side-by-side comparison view would be visible after the video translation to help you make adjustments. Once complete you are ready to export your translation!",

    feature_title:'Automated translation memory: Use what you\'ve already translated',
    feature_desc:"<p>Taption keeps all your source and target sentences from previously translated videos or audio in one place. Whenever it spots a similar or identical phrase, you'll see the matching translation right away—so you can reuse or adjust it as needed. It's a simple way to save time, stay consistent, and get more done. </p> <ul> <li><strong>Automated Memory</strong>: Gathers all your past translations into one central location.</li> <li><strong>Quick Matching</strong>: Detects similar or identical source text, then shows you the right translation immediately.</li><li><strong>Meaning-based Similarities</strong>: Not limited to character-level matching—also captures the meaning behind the text for a more accurate translation.</li>  <li><strong>Easy Reuse</strong>: Lets you choose whether to keep the original translation or make small tweaks.</li> <li><strong>Consistency and Productivity</strong>: Helps you maintain the same style and terminology across projects, speeding up the entire translation process.</li> </ul>",
    feature_poster_url:'./Translation_Memory_English.webp',
    feature_youtube:'https://www.youtube.com/embed/3VJx1KM1txQ?autoplay=1',

    feature_tutorial_title:'Solving video and audio translation challenges',
    feature_tutorial_desc:"<p>Taption's video translator and audio to text converter are designed to address common issues in traditional subtitle translation processes. Our AI-powered technology overcomes these major challenges:</p><ul><li><strong>Timing Sync:</strong> Precisely adjusts to ensure subtitles are perfectly synchronized with video and audio timing.</li><li><strong>Content Flow:</strong> Enhances the coherence of subtitles to provide a smooth viewing experience.</li><li><strong>Accuracy:</strong> Prevents discrepancies that literal translations might cause, ensuring accurate message delivery across languages.</li></ul><p>Supporting over 40 languages, including English to Mandarin, English to Japanese, English to Korean or English to Thai, Taption allows you to easily create and export bilingual subtitles. Our online platform eliminates the need to download software, making it easy to start using this feature. Whether you're a YouTube content creator, an educator transcribing lectures saved in Google Drive, or organizing international Zoom or Webex conferences, Taption's video translation software integrates seamlessly. It supports direct integration with platforms like YouTube, Google Drive, Zoom, and Webex, making it easy to translate and manage your video content across various languages and formats.</p><p>For bilingual subtitles, refer to this tutorial <a class='link' href='https://www.taption.com/add-subtitles'>on bilingual subtitles</a>. Check out the following tutorial on our automated transcription and translation service:</p>",
   
    //  feature_tutorial_desc:"<p>Taption's video translation software is specifically designed to address common issues encountered in traditional subtitle translation processes. Our technology, overcomes the following three major challenges, ensuring both the quality of translation and the viewing experience:</p><ul><li><strong>Timing Sync:</strong> Precisely adjusts to ensure that subtitles are perfectly synchronized with the video timing, crucial for languages like Vietnamese to English or English to Portuguese.</li><li><strong>Content Flow:</strong> Enhances the coherence of subtitles to provide a smooth viewing experience, whether you're translating from Korean to English or using our video translator for other language pairs.</li><li><strong>Accuracy:</strong> Prevents the discrepancies that literal translations might cause, ensuring accurate message delivery across all supported languages, including Mandarin to Indonesian and Indonesian to Mandarin.</li></ul><p>Supporting over 40 languages, Taption allows you to easily create and export bilingual subtitles, meeting the needs of multilingual video translation comprehensively. Our online platform eliminates the need to download any software to start using this feature. Whether you're a filmmaker requiring English to Japanese translation, an educator needing Russian to English subtitles, or an organizer of international conferences requiring Portuguese to English translations, Taption's video translation software is your optimal choice, ensuring that every viewer enjoys a barrier-free and high-quality viewing experience.</p><p>Whether you're translating MP4 or MP3 files, or need to convert M4A to MP3, our editing platform makes the process smoother and more time-efficient. For bilingual subtitles, refer to this tutorial <a class='link' href='https://www.taption.com/add-subtitles'>on bilingual subtitles</a>. Check out the following tutorial on our automated transcription and translation process:</p>", 
   feature_tutorial_poster_url:'./translate_tutorial_poster.webp',
    feature_tutorial_youtube:'https://www.youtube.com/embed/GyPnMIYkvAc?autoplay=1',

    feature_extra_title:"Frequently asked questions about video translation",
    feature_extra_desc:"<ul><li><strong>What types of video and audio formats can be translated?</strong><p>Taption supports a variety of video and audio formats, including MOV, AVI, WMV, FLV, MPEG, OGG, MP4, WAV, MP3, and M4A. This versatility allows users to handle a wide range of common file types to meet different transcription and translation needs.</p></li><li><strong>How long does video or audio translation take?</strong><p>Our AI-powered video translator and speech to text software work quickly, typically completing transcription and translation within 1 minute. The specific time may vary based on the length and complexity of the video or audio file.</p></li><li><strong>What is the pricing standard for video and audio translation?</strong><p>For Taption's video and audio translation services, including YouTube to text conversions, we use a unique billing method. We deduct half the number of minutes of the total length of the video or audio file. For example, if a video is 20 minutes long, 10 minutes will be deducted from the user's account. This pricing method is designed to provide users with cost-effective transcription and translation services.</p></li></ul>"
}